export const URL_BASE = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "http://localhost:8000/";

export const REFRESH_INTERVAL = process.env.REACT_APP_REFRESH_INTERVAL
  ? process.env.REACT_APP_REFRESH_INTERVAL
  : 1000;

export const ADMIN_URL = "admin/";

export const QUEUE_START_NUMBER = process.env.REACT_APP_QUEUE_START_NUMBER
  ? process.env.REACT_APP_QUEUE_START_NUMBER
  : 1;

export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN
  ? process.env.REACT_APP_COOKIE_DOMAIN
  : "localhost";

export const POLLING_INTERVAL = {
  pollingInterval: REFRESH_INTERVAL,
};

export const ADMIN_POLLING_INTERVAL = {
  pollingInterval: REFRESH_INTERVAL * 2,
};

export const MS_IN_MINUTE = 1000 * 60;

export const _15_MINUTES = MS_IN_MINUTE * 15;

export const MS_IN_HOUR = MS_IN_MINUTE * 60;

export const ICONS = [
  "ant",
  "bee",
  "bike",
  "bird",
  "boat",
  "car",
  "cat",
  "fish",
  "flower",
  "helicopter",
  "ladybird",
  "leaf",
  "palm",
  "pig",
  "plane",
  "rabbit",
  "rocket",
  "ship",
  "spruce",
  "tree",
  "turtle",
];
