import React, { useRef, useEffect } from "react";
import Button from "../prototypes/Button";
import { Link, useNavigate } from "react-router-dom";
import isLogged from "../../utils/isLogged";
import useFetch from "../../utils/hooks/useFetch";
import { useTranslation } from "react-i18next";

function useOutsideAlerter(ref, setOpen, open) {
  useEffect(() => {
    if (!open) return;
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, open, setOpen]);
}

const Menu = ({ open, setOpen, clicked }) => {
  const { t } = useTranslation();
  const logged = isLogged();
  const navigate = useNavigate();
  const { data } = useFetch("admin/get-all-queues");
  const handleLogout = () => {
    setOpen(false);
    localStorage.setItem("user", "");
    navigate("/");
    window.location.reload(); //for development
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setOpen, open, clicked);
  return (
    <nav
      ref={wrapperRef}
      className={
        "main-menu" +
        (clicked ? (open ? " main-menu-opened" : " main-menu-closed") : "")
      }
    >
      <ul>
        {data &&
          data.map((queue, i) => {
            return (
              queue.visibleInMenu && (
                <li key={i}>
                  <Link to={"/queue/" + queue.queueID}>
                    <Button
                      onClick={() => {
                        setOpen(false);
                      }}
                      variant="link"
                    >
                      {queue.name}
                    </Button>
                  </Link>
                </li>
              )
            );
          })}
        {
          <li>
            <Link to="/clinic/1">
              <Button
                onClick={() => {
                  setOpen(false);
                }}
                variant="link"
              >
                {t("All-medical-offices")}
              </Button>
            </Link>
          </li>
        }
        {logged && (
          <li>
            <Button onClick={() => handleLogout()}>{t("Log-out")}</Button>
            <Link to={"/admin"}>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
                variant="link"
              >
                {t("Settings")}
              </Button>
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Menu;
