export const validatePhoneNumber = (phoneNumber) => {
  const regex = /^((\+){1}[0-9]{2}( )?)?([0-9]{3}( )?){3}$/;
  if (regex.test(phoneNumber)) {
    return true;
  } else {
    return false;
  }
};

export const validateCardId = (cardId) => {
  const regex = /^([0-9]{4})$/;
  if (regex.test(cardId)) {
    return true;
  } else {
    return false;
  }
};
