import React from "react";
import ContentSelector from "./components/ContentSelector";
import Layout from "./components/layout/Layout";
import setUser from "./utils/setUser";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

function App() {
  setUser();
  return (
    <Provider store={store}>
      <div className="App">
      <ReactNotifications />
        <Layout>
          <ContentSelector />
        </Layout>
      </div>
    </Provider>
  );
}

export default App;
