import React from "react";
import { Link } from "react-router-dom";
const NotFoundPage = () => {
  return (
    <>
      <div></div>
      <Link to="/">
        <img
          style={{
            border: "unset",
            width: "50%",
            display: "block",
            margin: "10% auto 0",
            outline: "unset",
            backgroundColor: "unset",
          }}
          src="images/err-404-not-found.svg"
          alt="notFound"
          name="saveForm"
          class="btTxt submit"
          id="saveForm"
        />
      </Link>
    </>
  );
};

export default NotFoundPage;
