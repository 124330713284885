import axios from "axios";
import { URL_BASE } from "./consts";

const postData = async (url, payload) => {
  let data = null,
    error = null,
    loading = true;

  try {
    const response = await axios.post(URL_BASE + url, payload);
    data = response.data;
  } catch (err) {
    console.error(err.message);
    error = err.message;
  }
  loading = false;

  return {
    data,
    error,
    loading,
  };
};

export default postData;
