import React from "react";
import { Link } from "react-router-dom";

const Logo = () => (
  <h1>
    <Link to="/">wait||safe</Link>
  </h1>
);

export default Logo;
