import React from "react";
import Logo from "./Logo";
import MenuHamburger from "./MenuHamburger";
import LanguageSelector from "../prototypes/LanguageSelector";

const Header = ({ openMenu, setOpenMenu, setMenuClicked }) => (
  <header className="top-header">
    <Logo />
    <LanguageSelector />
    <MenuHamburger
      open={openMenu}
      setOpen={setOpenMenu}
      setClicked={setMenuClicked}
    />
  </header>
);

export default Header;
