import getData from "./getData";

const setUser = () => {
  const token = localStorage.getItem("token");
  if (token) {
    getData("user-info").then((r) => {
      if (r.data) localStorage.setItem("user", JSON.stringify(r.data));
      if (process.env.NODE_ENV === "development") {
        console.log(localStorage.getItem("user"));
      }
    });
  }
};

export default setUser;
