import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="container">
      <a
        className="footer-text"
        target="_blank"
        rel="noreferrer"
        href="https://futurelabs.psnc.pl/"
      >
        &copy; 2016-2023 PSNC Futurelabs
      </a>
      <div className="footer-links">
        <Link to="/help">{t("Help")}</Link>
        <Link to="/privacy">{t("Privacy")}</Link>
        <Link to="/terms">{t("Terms")}</Link>
      </div>
    </footer>
  );
};

export default Footer;
