import React from "react";
import { useGetAllCardsQuery } from "../../../redux/serverApi";
import { ADMIN_POLLING_INTERVAL } from "../../../utils/consts";
import BackToAdmin from "../../prototypes/BackToAdmin";
import Card from "../../prototypes/Card";
import Loading from "../../prototypes/Loading";
import { useTranslation } from "react-i18next";

const AllCardsPage = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetAllCardsQuery(0, ADMIN_POLLING_INTERVAL);
  return (
    <>
      <div className="def-div mb-3">
        <h1>{t("All-cards")}</h1>
        <BackToAdmin />
      </div>
      <div className="all-cards-container">
        {isLoading && <Loading />}
        {!data && !isLoading && (
          <div>
            <p>{t("Nothing-to-show")}</p>
          </div>
        )}
        {data && data.map((card, i) => <Card key={i} {...card} />)}
      </div>
    </>
  );
};

export default AllCardsPage;
