import {
  createAsyncThunk,
  createListenerMiddleware,
  createSlice,
} from "@reduxjs/toolkit";
import axios from "axios";
import { URL_BASE } from "../utils/consts";
import NotificationSound from "../utils/sounds/notification-sound1.mp3";

export const fetchQueue = createAsyncThunk("queue/fetch", async (queueID) => {
  try {
    const res = await axios.get(URL_BASE + "queue/" + queueID);
    return res.data;
  } catch (err) {
    throw err;
  }
});

const initialState = {
  activeQueues: [],
  error: null,
  firstVisitChange: [],
};

const queueSlice = createSlice({
  name: "queue",
  initialState,
  reducers: {
    setFirstVisitChange: (state, action) => {
      const { queueID, property } = action.payload;
      while (state.firstVisitChange.length <= queueID) {
        state.firstVisitChange.push(false);
      }
      state.firstVisitChange[queueID] = property;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQueue.fulfilled, (state, { payload }) => {
      state.activeQueues[payload.queueID] = payload;
    });
    builder.addCase(fetchQueue.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload.errorMessage;
      } else {
        state.error = action.error.message;
      }
    });
  },
});
export const { setFirstVisitChange } = queueSlice.actions;
export const listenerMiddleware = createListenerMiddleware();
export const queueReducer = queueSlice.reducer;

listenerMiddleware.startListening({
  predicate: (action, currentState, previousState) => {
    let queueChanged = false;

    for (const queueId of Object.keys(currentState.queue.activeQueues)) {
      const queue = currentState.queue.activeQueues[queueId];
      const previousQueue = previousState.queue.activeQueues[queueId];

      if (queue && queue.visits && previousQueue && previousQueue.visits) {
        const visitsChanged =
          JSON.stringify(queue.visits[0]) !==
          JSON.stringify(previousQueue.visits[0]);

        if (visitsChanged) {
          queueChanged = true;
          break;
        }
      }
    }
    return queueChanged;
  },
  effect: (action, listenerApi) => {
    const { queueID } = action.payload;
    console.log("First place changed on queue with id", queueID);
    const audio = new Audio(NotificationSound);
    audio
      .play()
      .then((_) => {
        console.log("Autoplay started...");
      })
      .catch((error) => {
        console.warn("Can't run sound...");
      });
    listenerApi.dispatch(setFirstVisitChange({ queueID, property: true }));
  },
});
