import { useTranslation } from "react-i18next";

const PrivacyPage = () => {
  const { t } = useTranslation();

  return (
    <div className="def-div">
      <h1 className="ta-c">{t("Privacy-policy")}</h1>

      <p>
        <strong>Waitsafe.pl</strong> takes your privacy seriously. To better
        protect your privacy, we provide this privacy policy notice explaining
        the way your personal information is collected and used.
      </p>

      <h2>Collection of Routine Information</h2>

      <p>
        This <strong>website</strong> tracks basic information about their{" "}
        <strong>visitors</strong>. This information includes, but is not limited
        to, IP addresses, <strong>browser</strong> details, timestamps, and
        referring pages. None of this information can personally identify
        specific <strong>visitors</strong> to this <strong>website</strong>. The
        information is tracked for routine administration and maintenance
        purposes.
      </p>

      <h2>Cookies</h2>

      <p>
        Where necessary, this <strong>website</strong> uses cookies to store
        information about a visitor’s preferences and history in order to better
        serve the <strong>visitor</strong> and/or present the{" "}
        <strong>visitor</strong> with customized content.
      </p>

      <h2>Advertisement and Other Third Parties</h2>

      <p>
        Advertising partners and other third parties may use cookies, scripts,
        and/or web beacons to track <strong>visitors</strong> activities on this{" "}
        <strong>website</strong> in order to display advertisements and other
        useful information. Such tracking is done directly by the third parties
        through their own servers and is subject to their own privacy policies.
        This <strong>website</strong> has no access or control over these
        cookies, scripts, and/or web beacons that may be used by third parties.
        Learn how to{" "}
        <a href="http://www.google.com/privacy_ads.html">
          opt out of Google’s cookie usage
        </a>
        .
      </p>

      <h2>Security</h2>

      <p>
        The security of your personal information is important to{" "}
        <strong>us</strong>, but remember that no method of transmission over
        the Internet, or method of electronic storage, is 100% secure. While{" "}
        <strong>we</strong> strive to use commercially acceptable means to
        protect your personal information, <strong>we</strong> cannot guarantee
        its absolute security.
      </p>

      <h2>Changes To This Privacy Policy</h2>

      <p>
        This Privacy Policy is effective as of 02.06.2016r. and will remain in
        effect except with respect to any changes in its provisions in the
        future, which will be in effect immediately after being posted on this
        page. <strong>We</strong> reserve the right to update or change{" "}
        <strong>our</strong> Privacy Policy at any time, and you should check
        this Privacy Policy periodically. If <strong>we</strong> make any
        material changes to this Privacy Policy, <strong>we</strong> will notify
        you either through the email address you have provided{" "}
        <strong>us</strong>, or by placing a prominent notice on{" "}
        <strong>our</strong> <strong>website</strong>.
      </p>

      <h2>Contact Information</h2>

      <p>
        For any questions or concerns regarding the privacy policy, please send{" "}
        <strong>us</strong> an email to{" "}
        <a href="mailto:kontakt@futurelabs.psnc.pl.">
          kontakt@futurelabs.psnc.pl
        </a>
        .
      </p>
    </div>
  );
};

export default PrivacyPage;
