import React, { useState, useEffect } from "react";
import QueueItem from "./QueueItem";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import postData from "../../utils/postData";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DnDQueue = (props) => {
  const { t } = useTranslation();
  const { data, loading } = props;

  const sort = (a, b) => {
    if (!a.order) return 1;
    if (!b.order) return -1;
    if (a.order.order > b.order.order) return 1;
    else if (a.order.order < b.order.order) return -1;
    else return 0;
  };

  const [dynamicQueue, setDynamicQueue] = useState(data.sort(sort));

  useEffect(() => {
    setDynamicQueue(data.sort(sort));
  }, [data]);

  const changeOrderInDB = (visitID, from, to) => {
    const payload = {
      visitID: visitID,
      from: from,
      to: to,
    };
    postData(`change-order/${props.queueID}`, payload);
    //window.location.reload()
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    changeOrderInDB(removed.visitID, startIndex + 1, endIndex + 1);
    return result;
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const queue = reorder(
      dynamicQueue,
      result.source.index,
      result.destination.index
    );

    setDynamicQueue(queue);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <div
            className="queue-container"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {!loading &&
              data &&
              dynamicQueue.map((el, k) => (
                <Draggable
                  key={el.visitID}
                  draggableId={el.cardNumber + "-" + el.visitID}
                  index={k}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <QueueItem
                        number={k}
                        icon={el.card.icon}
                        isFirst={k === 0}
                        cardNumber={`-${el.cardNumber}-`}
                        active={props.activeId === el.cardNumber ? true : false}
                        time={el.checkIn}
                        order={el.order ? el.order.order : null}
                        visitID={el.visitID}
                        isAdminPage={true}
                      >
                        <Link
                          className="btn-primary btn-admin btn-admin-remove"
                          to={"/admin/end-visit/" + el.visitID}
                        >
                          {t("End")}
                        </Link>
                        <Link
                          className="btn-primary btn-admin btn-admin-remove"
                          to={"/admin/delete-visit/" + el.visitID}
                        >
                          {t("Remove")}
                        </Link>
                      </QueueItem>
                    </div>
                  )}
                </Draggable>
              ))}
            {data && data.length === 0 && <h3>{t("No-visits-in-queue")}.</h3>}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DnDQueue;
