import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { useTranslation } from "react-i18next";
const AverageTimeChart = ({ data }) => {
  const { t } = useTranslation();
  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  const updateChart = (data) => {
    if (chartRef.current && data && data.averageTimePerQueue) {
      if (chartInstance) {
        chartInstance.destroy();
      }
      const chartData = {
        datasets: Object.keys(data.averageTimePerQueue).map(
          (queueName, index) => ({
            label: queueName,
            data: data.averageTimePerQueue[queueName],
            fill: false,
          })
        ),
      };
      const chartOptions = {
        scales: {
          y: {
            display: true,
            beginAtZero: true,
            title: {
              display: true,
              text: t("minutes"),
            },
          },
        },
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: true,
          },
        },
      };

      const ctx = chartRef.current.getContext("2d");
      const newChartInstance = new Chart(ctx, {
        type: "line",
        data: chartData,
        options: chartOptions,
      });

      setChartInstance(newChartInstance);
    }
  };

  useEffect(() => {
    if (data) {
      updateChart(data);
    }
  }, [data, t]);

  return (
    <div className="chrtPlots">
      <p>{t("Average-time") + " (" + t("minutes") + ")"}</p>
      <div>
        <canvas ref={chartRef} width={400} height={300}></canvas>
      </div>
    </div>
  );
};

export default AverageTimeChart;
