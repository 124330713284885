import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TurnOnNotifications = (props) => {
  const { t } = useTranslation();

  const link = props.cardID
    ? `/notifications?cardid=${[props.cardID]}`
    : `/notifications`;

  return (
    <div className="notifications-banner">
      <Link className="btn btn-primary" to={link}>
        {t("SMS-notifications")}
      </Link>
    </div>
  );
};

export default TurnOnNotifications;
