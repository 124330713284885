import React from "react";
import "../../styles/instructions.scss";
import { useTranslation } from "react-i18next";

const HelpPage = (props) => {
  const { t } = useTranslation();

  return (
    <div className="index">
      <div className="before-steps">
        <div className="image-container">
          <img alt="Logo" src="/images/instruction-icons/waitsafe-logo.svg" />
          <img
            alt="Fl psnc"
            src="/images/instruction-icons/futurelabs-30-logo.svg"
          />
        </div>
        <p className="dzie-dobry-w-dniu">
          {t("Hello!")}
          <br />
          <br />
          {t("instruction-text1")}
          <br />
          <br />
          {t("instruction-text2")}
        </p>
        <p className="p">{t("instruction-text3")}</p>
      </div>

      <div className="steps">
        <div className="step wrap">
          <div className="overlap group-1">
            <div className="rectangle-2" />
            <div className="text-wrapper-2">1</div>
            <img
              className="front-desk"
              alt="Front desk"
              src="/images/instruction-icons/front-desk.svg"
            />
          </div>
          <div className="text-group">
            <h2>{t("instruction-text4")}</h2>
            <p className="ka-da-karta-posiada">
              {t("instruction-text5")}
              <br />
              <br />
              {t("instruction-text6")}
            </p>
          </div>
        </div>
        <div className="step-2 wrap">
          <div className="overlap group-2">
            <div className="rectangle-2" />
            <div className="text-wrapper-4">2</div>
            <img
              className="smartphone"
              alt="Smartphone"
              src="/images/instruction-icons/smartphone-icon.svg"
            />
          </div>
          <div className="text-group">
            <h2>
              {t("instruction-text7")}: <br />- {t("instruction-text8")} <br />-{" "}
              {t("instruction-text9")}
            </h2>
            <p className="w-poczekalni-na">
              {t("instruction-text10")}. <br />
              <br />
              {t("instruction-text11")} waitsafe.psnc.pl
            </p>
          </div>
        </div>
        <div className="step-3 wrap">
          <div className="overlap group-3">
            <div className="rectangle-2" />
            <div className="text-wrapper-4">3</div>
            <img
              className="sakura"
              alt="Sakura"
              src="/images/instruction-icons/sakura.svg"
            />
          </div>
          <div className="text-group">
            <h2>{t("instruction-text12")}</h2>
            <p className="przypisuj-c-numer">
              {t("instruction-text13")} <br />
              <br />
              {t("instruction-text14")} <br />
              <br />
              {t("instruction-text15")}
            </p>
          </div>
        </div>
        <div className="step-4 wrap">
          <div className="overlap group-4">
            <div className="rectangle-2" />
            <div className="text-wrapper-4">4</div>
            <img
              className="pediatrician"
              alt="Pediatrician"
              src="/images/instruction-icons/pediatrician.svg"
            />
          </div>
          <div className="text-group">
            <h2>{t("instruction-text16")}</h2>
            <p className="gdy-numer-twojej">
              {t("instruction-text17")} <br />
              <br />
              {t("instruction-text18")}
            </p>
          </div>
        </div>
      </div>
      <div className="image-container bottom-img">
        <img
          alt="phone-align"
          src="/images/instruction-icons/phone-align-card.svg"
        />
        <img alt="phone" src="/images/instruction-icons/phone.svg" />
      </div>
    </div>
  );
};

export default HelpPage;
