import React, { useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Menu from "./Menu";

function Layout(props) {
  const [open, setOpen] = useState(false);
  const [clicked, setClicked] = useState(false);
  return (
    <>
      <Header
        openMenu={open}
        setOpenMenu={setOpen}
        setMenuClicked={setClicked}
      />

      <main>{props.children}</main>
      <Footer />
      <Menu open={open} setOpen={setOpen} clicked={clicked} />
    </>
  );
}

export default Layout;
