import React from "react";
import { useParams } from "react-router-dom";
import useFetch from "../../utils/hooks/useFetch";
import { Navigate } from "react-router-dom";
import { warnNotification } from "../../utils/popNotifications";
import { useTranslation } from "react-i18next";

const CardPage = () => {
  const { t } = useTranslation();
  const { cardId } = useParams();
  const { data, loading } = useFetch(`card/${cardId}`);

  if (!loading) {
    if (data.queueID) {
      return (
        <Navigate
          to={`/queue/${data.queueID}?cardid=${cardId}`}
          replace={true}
        />
      );
    }
    warnNotification(`Warning`, `${t("No-card-found")}! ${t("Try-again")}.`);
    return <Navigate to={`/`} replace={true} />;
  }
};

export default CardPage;
