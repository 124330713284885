import React, { useState } from "react";
import BackToAdmin from "../../prototypes/BackToAdmin";
import { useTranslation } from "react-i18next";
import { useGetVisitStatsQuery } from "../../../redux/serverApi";
import VisitsChart from "./charts/VisitsChart";
import AverageTimeChart from "./charts/AverageTimeChart";
import WeekAverageTimeChart from "./charts/WeekAverageTimeChart";
import WeekVisitChart from "./charts/WeekVisitChart";
import Loading from "../../prototypes/Loading";

const StatisticsPage = () => {
  const { t } = useTranslation();
  const [timePeriod, setTimePeriod] = useState("day");
  const { data, isLoading } = useGetVisitStatsQuery({
    timePeriod,
  });

  const handleTimePeriodChange = (newTimePeriod) => {
    setTimePeriod(newTimePeriod);
  };
  return (
    <div>
      <div className="def-div mb-3">
        <h1>{t("Statistics")}</h1>
        <BackToAdmin />
      </div>
      <div className="all-cards-container">
        <div style={{ width: "100%" }}>
          <label>{t("Select-time-period")}:</label>
          <select
            value={timePeriod}
            onChange={(e) => handleTimePeriodChange(e.target.value)}
          >
            <option value="day">{t("Day")}</option>
            <option value="week">{t("Week")}</option>
            <option value="month">{t("Month")}</option>
            <option value="year">{t("Year")}</option>
          </select>
        </div>
        {isLoading && <Loading />}
        {!data && !isLoading && (
          <div>
            <p>{t("Nothing-to-show")}</p>
          </div>
        )}
        {data && (
          <div className={"stats-container"}>
            <div className={"stats-sum-hostpital"}>
              <h3>
                {t("Hospital")} - {t("Total-of-visits-per")} {t(timePeriod)}
              </h3>
              <p>
                {t("Average-time")}: {data.totalAverageTime} {t("minutes")}
              </p>
              <p>
                {t("Number-of-visits")}: {data.totalVisits}
              </p>
            </div>

            <h2>
              {t("Queues")} - {t("Statistics-for") + " "}
              {t(timePeriod)}
            </h2>
            <div>
              <VisitsChart data={data} />
              <AverageTimeChart data={data} />

              {data.weekVisits && timePeriod !== "day" ? (
                <WeekVisitChart data={data.weekVisits} />
              ) : (
                <p>
                  {t("Number-of-visits")} - {t("week-view")}:{" "}
                  {t("Nothing-to-show")}
                </p>
              )}
              {data.weekVisits && timePeriod !== "day" ? (
                <WeekAverageTimeChart data={data.weekVisits} />
              ) : (
                <p>
                  {t("Average-time")} - {t("week-view")}: {t("Nothing-to-show")}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StatisticsPage;
