import React, { useEffect } from "react";
import { REFRESH_INTERVAL } from "../../utils/consts";
import Queue from "../prototypes/Queue";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchQueue,
  setFirstVisitChange,
} from "../../redux/queueSlice";
import { useTranslation } from "react-i18next";

const ClinicPage = () => {
  const { t } = useTranslation();
  const queueParams = new URLSearchParams(window.location.search);
  const viewType = Number(queueParams.get("view"));

  const queue1 = Number(queueParams.get("q1")) || 1;
  const queue2 = Number(queueParams.get("q2")) || 2;

  const q1 = useSelector((state) => state.queue.activeQueues[queue1]);
  const q2 = useSelector((state) => state.queue.activeQueues[queue2]);
  const firstVisitChange1 = useSelector(
    (state) => state.queue.firstVisitChange[queue1]
  );
  const firstVisitChange2 = useSelector(
    (state) => state.queue.firstVisitChange[queue2]
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setFirstVisitChange({ queueID: queue1, property: false }));
    dispatch(setFirstVisitChange({ queueID: queue2, property: false }));
  }, [dispatch, queue1, queue2]);
  useEffect(() => {
    dispatch(fetchQueue(queue1));
    dispatch(fetchQueue(queue2));
    const int = setInterval(() => {
      dispatch(fetchQueue(queue1));
      dispatch(fetchQueue(queue2));
    }, REFRESH_INTERVAL);
    return () => clearInterval(int);
  }, [dispatch, queue1, queue2]);

  if (firstVisitChange1) {
    setTimeout(() => {
      dispatch(setFirstVisitChange({ queueID: queue1, property: false }));
    }, 10000);
  }
  if (firstVisitChange2) {
    setTimeout(() => {
      dispatch(setFirstVisitChange({ queueID: queue2, property: false }));
    }, 10000);
  }

  return (
    <>
      <div className="def-div mb-3">
        <h1 className="ta-c">{t("All-medical-offices")}</h1>
      </div>
      <div className="flex-v-center clinic-container">
        {!q1 && !q2 ? <h2>{t("Nothing-to-show")}</h2> : <></>}
        {q1 && (
          <div className="clinic-item">
            <h3>
              {t("Office")} {q1 && q1.queueID}
            </h3>
            <h2>{q1 && q1.name}</h2>
            {q1 && q1.visits && (
              <Queue
                data={q1.visits}
                loading={q1 ? false : true}
                firstVisitChange={firstVisitChange1}
                viewType={viewType}
              />
            )}
          </div>
        )}
        {q2 && (
          <div className="clinic-item">
            <h3>
              {t("Office")} {q2.queueID}
            </h3>
            <h2>{q2.name}</h2>
            {q2 && q2.visits && (
              <Queue
                data={q2.visits}
                loading={q2 ? false : true}
                firstVisitChange={firstVisitChange2}
                viewType={viewType}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ClinicPage;
