import React from "react";
import Loading from "./Loading";
import QueueItem from "./QueueItem";
import { QUEUE_START_NUMBER } from "../../utils/consts";
import { useTranslation } from "react-i18next";

const Queue = (props) => {
  const { t } = useTranslation();
  const { data, loading } = props;
  const sort = (a, b) => {
    if (!a.order) return 1;
    if (!b.order) return -1;
    if (a.order.order > b.order.order) return 1;
    else if (a.order.order < b.order.order) return -1;
    else return 0;
  };

  const dataForSort = [...data];

  return (
    <div
      className={"queue-container " + props.className ? props.className : ""}
    >
      {loading && <Loading />}
      {!loading &&
        dataForSort &&
        dataForSort
          .sort(sort)
          .map((el, k) => (
            <QueueItem
              key={k}
              keyOrder={k}
              number={k + QUEUE_START_NUMBER}
              viewType={props.viewType}
              vievedCardId={
                props.vievedCardId === el.cardNumber ? props.vievedCardId : null
              }
              waitTimeMin={props.waitTimeMin}
              firstVisitChange={props.firstVisitChange}
              isFirst={k === 0}
              icon={el.card.icon}
              cardNumber={`-${el.cardNumber}-`}
              active={props.activeId === el.cardNumber ? true : false}
              time={el.checkIn}
              order={el.order ? el.order.order : null}
              visitID={el.visitID}
            />
          ))}
      {data && data.length === 0 && (
        <h3 className="ta-c">{t("Nothing-to-show")}</h3>
      )}
    </div>
  );
};

export default Queue;
