import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faX } from "@fortawesome/free-solid-svg-icons";

const MenuHamburger = ({ open, setOpen, setClicked }) => {
  return (
    <div style={{ minWidth: "14px" }}
      onClick={() => {
        setClicked(true);
        setOpen(!open);
      }}
    >
      {!open && <FontAwesomeIcon icon={faBars} />}
      {open && <FontAwesomeIcon icon={faX} />}
    </div>
  );
};

export default MenuHamburger;
