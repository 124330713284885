import React from "react";
import { useTranslation } from "react-i18next";
//import { parseCheckOutTime } from "../../utils/functions";
import "../../styles/queue.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket } from "@fortawesome/free-solid-svg-icons";

const QueueItem = (props) => {
  const { t } = useTranslation();

  const renderIsFirst = () => {
    return props.isFirst && !props.isAdminPage ? (
      <div className="queue-item-right">{t("Visit")}</div>
    ) : (
      ""
    );
  };

  const renderIsUserVisit = (time, order) => {
    return !props.isFirst && time > 0 && order ? (
      <div className="queue-item-right">{`~${time * order} ${t(
        "minutes"
      )}`}</div>
    ) : (
      ""
    );
  };

  const renderTime = () => {
    if (props.time) {
      var time = new Date(props.time).toLocaleTimeString();
      return props.isAdminPage ? (
        <div className="queue-item-right">
          <FontAwesomeIcon icon={faRightToBracket} /> {time}
        </div>
      ) : (
        ""
      );
    }
  };

  return (
    <div
      className={
        "bg-w queue-item" +
        (props.viewType === 1 ? " view-type " : "") +
        (props.active ? " queue-item-active " : "") +
        (props.firstVisitChange && props.isFirst
          ? " blinking-element-active "
          : "") +
        (props.isFirst ? " first-item " : "") +
        (props.vievedCardId ? " my-card " : "")
      }
    >
      <div className="queue-item-left">
        <div className="queue-item-number">
          <h1>{props.number}</h1>
        </div>
        {!props.isAdminPage ? (
          <div className="queue-item-icon-wrapper">
            <img
              src={`/images/icons/${props.icon}.svg`}
              alt={props.icon}
              className="queue-item-icon"
            ></img>
          </div>
        ) : (
          ""
        )}
        <div className="queue-item-id">
          <h1>{props.cardNumber}</h1>
        </div>
      </div>
      {renderIsFirst()}
      {renderIsUserVisit(props.waitTimeMin, props.keyOrder)}
      {renderTime()}
      {props.children ? (
        <div className={props.isAdminPage ? "menage-buttons" : ""}>
          {props.children}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default QueueItem;
