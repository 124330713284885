import React, { useState } from "react";
import { useAddReaderMutation } from "../../../redux/serverApi";
import BackToAdmin from "../../prototypes/BackToAdmin";
import Button from "../../prototypes/Button";
import {
  successNotification,
  warnNotification,
  errorNotification,
} from "../../../utils/popNotifications";
import { useTranslation } from "react-i18next";

const AddReaderPage = () => {
  const { t } = useTranslation();
  const [readerID, setReaderId] = useState("");
  const [queueID, setQueueID] = useState(0);
  const [addReader] = useAddReaderMutation();
  const [errorReader, seterrorReader] = useState(false);

  const handleButton = (e) => {
    e.preventDefault();
    if (!readerID) {
      seterrorReader(true);
      return;
    }
    seterrorReader(false);
    const payload = {
      readerID: readerID,
      queueID: queueID,
    };
    addReader({ payload })
      .unwrap()
      .then((res) => {
        if (process.env.NODE_ENV === "development") {
          console.log(res);
        }
        successNotification(
          `${t("Success")}`,
          `${t("Reader") + " " + t("Added")}`
        );
      })
      .catch((err) => {
        console.error(err.message);
        if (err.data.message === "Exists") {
          warnNotification(`Warning`, err.data.error);
        } else {
          errorNotification(`Error`, `${t("Error-while-adding")}!`);
        }
      });
  };

  var errorReaderProps = {};
  if (errorReader) {
    errorReaderProps.className = "error";
  }

  return (
    <>
      <div className="def-div mb-3">
        <h1>{t("Add") + t("Reader")}</h1>
        <BackToAdmin />
        <form onSubmit={(e) => handleButton(e)}>
          <label>
            {t("Reader-id")}:
            <input
              type="text"
              name="readerID"
              id="readerID"
              value={readerID}
              onChange={(e) => {
                setReaderId(e.target.value);
              }}
              onKeyDown={(e) => {
                setReaderId(e.target.value);
              }}
              {...errorReaderProps}
            />
          </label>
          <label>
            {t("Function")} / {t("Queue") + " ID"}
            <br />
            <small>{t("Readers-func")}</small>
            <input
              type="number"
              min={0}
              name="queue"
              id="queue"
              value={queueID}
              onChange={(e) => setQueueID(e.target.value)}
            />
          </label>
          <Button onClick={(e) => handleButton(e)}>{t("Add")}</Button>
        </form>
      </div>
    </>
  );
};

export default AddReaderPage;
