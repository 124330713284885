//import {  MS_IN_HOUR, _15_MINUTES } from "./consts";

export const parseCheckOutTime = (time, order) => {
  /*const checkIn = new Date(time);
  const checkOut = new Date(checkIn.getTime() + _15_MINUTES * order);
  const hh = checkOut.getHours().toString().padStart(2, "0");
  const mm = checkOut.getMinutes().toString().padStart(2, "0");
  return `${hh}:${mm}`;*/
  //const checkOut = new Date(_15_MINUTES * order);
  //const mm = checkOut.getMinutes().toString().padStart(2, "0");
  return `do ${15 * order} minut`;
};
