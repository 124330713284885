import axios from "axios";
import { URL_BASE } from "./consts";

const getData = async (url) => {
  let data = null,
    error = null,
    loading = true;

  try {
    const response = await axios.get(URL_BASE + url);
    data = response.data;
  } catch (err) {
    console.error(err.message);
    error = err.message;
  }
  loading = false;

  return {
    data,
    error,
    loading,
  };
};

export default getData;
