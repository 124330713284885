import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ADMIN_URL, URL_BASE } from "../utils/consts";

export const serverApi = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: URL_BASE }),
  endpoints: (builder) => ({
    getAllQueues: builder.query({
      query: () => `${ADMIN_URL}get-all-queues`,
      providesTags: ["Queue"],
    }),
    getQueue: builder.query({
      query: (id) => `${ADMIN_URL}get-queue/${id}`,
      providesTags: ["Queue"],
    }),
    editQueue: builder.mutation({
      query: ({ id, payload }) => ({
        url: `${ADMIN_URL}edit-queue/${id}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Queue"],
    }),
    addQueue: builder.mutation({
      query: ({ payload }) => ({
        url: `${ADMIN_URL}add-queue`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Queue"],
    }),
    deleteQueue: builder.query({
      query: (id) => `${ADMIN_URL}delete-queue/${id}`,
      invalidatesTags: ["Queue"],
    }),

    getAllCards: builder.query({
      query: () => `${ADMIN_URL}get-all-cards`,
    }),
    getCard: builder.query({
      query: (id) => `${ADMIN_URL}get-card/${id}`,
    }),

    editCard: builder.mutation({
      query: ({ id, payload }) => ({
        url: `${ADMIN_URL}edit-card/${id}`,
        method: "POST",
        body: payload,
      }),
    }),
    addCard: builder.mutation({
      query: ({ payload }) => ({
        url: `${ADMIN_URL}add-card`,
        method: "POST",
        body: payload,
      }),
    }),
    deleteCard: builder.query({
      query: (id) => `${ADMIN_URL}delete-card/${id}`,
    }),

    getAllReaders: builder.query({
      query: () => `${ADMIN_URL}get-all-readers`,
    }),
    getReader: builder.query({
      query: (id) => `${ADMIN_URL}get-reader/${id}`,
    }),
    editReader: builder.mutation({
      query: ({ id, payload }) => ({
        url: `${ADMIN_URL}edit-reader/${id}`,
        method: "POST",
        body: payload,
      }),
    }),
    addReader: builder.mutation({
      query: ({ payload }) => ({
        url: `${ADMIN_URL}add-reader`,
        method: "POST",
        body: payload,
      }),
    }),
    deleteReader: builder.query({
      query: (id) => `${ADMIN_URL}delete-reader/${id}`,
    }),

    addVisit: builder.mutation({
      query: ({ payload }) => ({
        url: `${ADMIN_URL}add-visit`,
        method: "POST",
        body: payload,
      }),
    }),
    endVisit: builder.query({
      query: (id) => `${ADMIN_URL}end-visit/${id}`,
    }),
    deleteVisit: builder.query({
      query: (id) => `${ADMIN_URL}delete-visit/${id}`,
    }),
    getVisitStats: builder.query({
      query: ({ timePeriod }) =>
        `${ADMIN_URL}statistics?timePeriod=${timePeriod}`,
    }),
    getWeeklyVisitStats: builder.query({
      query: (weekTimePeriod) =>
        `${ADMIN_URL}weeklyStatistics?timePeriod=${weekTimePeriod}`,
    }),
  }),
});

export const {
  useGetAllQueuesQuery,
  useGetAllCardsQuery,
  useGetAllReadersQuery,
  useGetCardQuery,
  useGetReaderQuery,
  useGetQueueQuery,
  useEditQueueMutation,
  useEditCardMutation,
  useEditReaderMutation,
  useAddQueueMutation,
  useAddCardMutation,
  useAddReaderMutation,
  useAddVisitMutation,
  useDeleteCardQuery,
  useDeleteReaderQuery,
  useDeleteQueueQuery,
  useDeleteVisitQuery,
  useEndVisitQuery,
  useGetVisitStatsQuery,
  useGetWeeklyVisitStatsQuery,
} = serverApi;
