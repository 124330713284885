import React, { useState } from "react";
import { useAddCardMutation } from "../../../redux/serverApi";
import { ICONS } from "../../../utils/consts";
import BackToAdmin from "../../prototypes/BackToAdmin";
import Button from "../../prototypes/Button";
import {
  successNotification,
  errorNotification,
} from "../../../utils/popNotifications";
import { validateCardId } from "../../../utils/dataValidators";
import { useTranslation } from "react-i18next";

const AddCardPage = () => {
  const { t } = useTranslation();
  const [cardNumber, setCardNumber] = useState("");
  const [name, setName] = useState("");
  const [icon, setIcon] = useState("ant");
  const [queueID, setQueueID] = useState(0);
  const [addCard] = useAddCardMutation();
  const [errorCard, setErrorCard] = useState(false);

  const handleButton = (e) => {
    e.preventDefault();
    if (!validateCardId(cardNumber)) {
      setErrorCard(true);
      return;
    }
    setErrorCard(false);
    const card = {
      cardNumber: cardNumber,
      name: name,
      icon: icon,
      queueID: queueID,
    };

    addCard({ payload: card })
      .unwrap()
      .then((res) => {
        successNotification(
          `${t("Success")}`,
          `${t("Card") + " " + t("Added")}` +
            ": \n ID: " +
            JSON.stringify(res.cardNumber) +
            " -> " +
            `${t("Queue")}` +
            ": " +
            JSON.stringify(res.queueID)
        );
      })
      .catch((err) => {
        console.error(err.message);
        errorNotification(`Error`, `${t("Error-while-adding")}!`);
      });
  };

  var errorCardProps = {};
  if (errorCard) {
    errorCardProps.className = "error";
  }

  return (
    <>
      <div className="def-div mb-3">
        <h1>{t("Add-card")}</h1>
        <BackToAdmin />
        <form onSubmit={(e) => handleButton(e)}>
          <label>
            {t("Card-number")}:
            <input
              {...errorCardProps}
              required
              type="number"
              name="cardNumber"
              id="cardNumber"
              placeholder="0000"
              min={0}
              max={4}
              value={cardNumber}
              onChange={(e) => {
                setCardNumber(e.target.value);
              }}
              onKeyDown={(e) => {
                setCardNumber(e.target.value);
              }}
            />
          </label>
          <label>
            {t("Card-name")}:
            <input
              required
              type="text"
              name="name"
              id="name"
              placeholder={t("Card")}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </label>
          <div>
            <label>
              {t("Card-icon")} <br />
              <img src={`/images/icons/${icon}.svg`} alt={"ikona - " + icon} />
              <select
                required
                name="icon"
                id="icon"
                onChange={(e) => setIcon(e.target.value)}
              >
                {ICONS.map((icon, i) => (
                  <option
                    key={i}
                    value={icon}
                    onSelect={(e) => setIcon(e.target.value)}
                  >
                    {icon}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <label>
            {t("Queue")}: (id)
            <input
              required
              type="number"
              min={0}
              name="queue"
              id="queue"
              value={queueID}
              onChange={(e) => setQueueID(e.target.value)}
            />
          </label>
          <Button onClick={(e) => handleButton(e)}>{t("Add")}</Button>
        </form>
      </div>
    </>
  );
};

export default AddCardPage;
