import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEditCardMutation, useGetCardQuery } from "../../../redux/serverApi";
import { ICONS } from "../../../utils/consts";
import BackToAdmin from "../../prototypes/BackToAdmin";
import Button from "../../prototypes/Button";
import {
  successNotification,
  errorNotification,
} from "../../../utils/popNotifications";
import { useTranslation } from "react-i18next";

const EditCardPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { cardId } = useParams();
  const { data, isLoading } = useGetCardQuery(cardId);
  const [cardNumber, setCardNumber] = useState("");
  const [name, setName] = useState("");
  const [icon, setIcon] = useState("");
  const [queueID, setQueueID] = useState(0);
  const [editCard] = useEditCardMutation();

  useEffect(() => {
    if (!isLoading && data) {
      const { cardNumber, name, icon, queueID } = data;
      setCardNumber(cardNumber);
      setQueueID(queueID);
      setIcon(icon);
      setName(name);
    }
  }, [cardId, data, isLoading]);

  const handleButton = (e) => {
    e.preventDefault();
    const card = {
      cardNumber: cardNumber,
      name: name,
      icon: icon,
      queueID: queueID,
    };

    editCard({ id: cardId, payload: card })
      .unwrap()
      .then((res) => {
        if (process.env.NODE_ENV === "development") {
          console.log(res);
        }
        successNotification(`${t("Success")}`, `${t("Updated")}`);
        navigate("/admin/all-cards");
      })
      .catch((err) => {
        console.error(err.message);
        errorNotification(
          `Error`,
          `${t("Card-editing-failed")}! ${t("Try-again")}.`
        );
      });
  };

  return (
    <>
      <div className="def-div mb-3">
        <h1>{t("Edit") + " " + t("Card")}</h1>
        <BackToAdmin />
        <form onSubmit={(e) => handleButton(e)}>
          <label>
            {t("Card-number")}:
            <input
              type="text"
              name="cardNumber"
              id="cardNumber"
              value={cardNumber}
              onChange={(e) => {
                setCardNumber(e.target.value);
              }}
              onKeyDown={(e) => {
                setCardNumber(e.target.value);
              }}
            />
          </label>
          <label>
            {t("Card-name")}:
            <input
              type="text"
              name="name"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </label>
          <div>
            <label>
              {t("Card-icon")} <br />
              <img src={`/images/icons/${icon}.svg`} alt={"ikona - " + icon} />
              <select
                name="icon"
                id="icon"
                onChange={(e) => setIcon(e.target.value)}
              >
                {ICONS.map((icon, i) => (
                  <option
                    key={i}
                    value={icon}
                    onSelect={(e) => setIcon(e.target.value)}
                  >
                    {icon}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <label>
            {t("Queue")}: (id)
            <input
              type="number"
              min={0}
              name="queue"
              id="queue"
              value={queueID}
              onChange={(e) => setQueueID(e.target.value)}
            />
          </label>
          <Button onClick={(e) => handleButton(e)}>{t("Update")}</Button>
        </form>
      </div>
    </>
  );
};

export default EditCardPage;
