import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAddQueueMutation } from "../../../redux/serverApi";
import BackToAdmin from "../../prototypes/BackToAdmin";
import Button from "../../prototypes/Button";
import {
  successNotification,
  errorNotification,
} from "../../../utils/popNotifications";

const AddQueuePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [queueID, setQueueID] = useState(0);
  const [name, setName] = useState("");
  const [waitTimeMin, setWaitTimeMin] = useState(15);
  const [visibleInMenu, setVisibleInMenu] = useState(false);
  const [addQueue] = useAddQueueMutation();

  const handleButton = (e) => {
    e.preventDefault();
    const payload = {
      queueID,
      name,
      waitTimeMin,
      visibleInMenu,
    };

    addQueue({ payload })
      .unwrap()
      .then((res) => {
        if (process.env.NODE_ENV === "development") {
          console.log(res.data);
        }
        successNotification(
          `${t("Success")}`,
          `${t("Queue") + " " + t("Created")}`
        );

        navigate("/admin/all-queues");
      })
      .catch((err) => {
        console.error(err.message);
        errorNotification(
          `Error`,
          `${t("Queue-editing-failed")}! ${t("Try-again")}.`
        );
      });
  };

  return (
    <>
      <div className="def-div mb-3">
        <h1>{t("Add") + " " + t("Queue")}</h1>
        <BackToAdmin />
        <form onSubmit={(e) => handleButton(e)}>
          <label>
            {t("Queue-id")}:
            <input
              type="number"
              min={0}
              name="queue"
              id="queue"
              value={queueID}
              onChange={(e) => setQueueID(e.target.value)}
            />
          </label>
          <label>
            {t("Queue-name")}:
            <input
              type="text"
              name="name"
              id="name"
              value={name}
              placeholder={t("Queue")}
              onChange={(e) => setName(e.target.value)}
            />
          </label>
          <label>
            {t("Visible-in-menu")}:
            <input
              type="checkbox"
              name="visibleInMenu"
              id="visibleInMenu"
              checked={visibleInMenu}
              onChange={(e) => setVisibleInMenu(!visibleInMenu)}
            />
          </label>
          <label>
            {t("Est-queue-wait-time")}:
            <input
              type="number"
              min={1}
              name="queue-wait-time"
              id="queue-wait-time"
              value={waitTimeMin}
              onChange={(e) => setWaitTimeMin(Number(e.target.value))}
            />
          </label>
          <Button onClick={(e) => handleButton(e)}>{t("Add")}</Button>
        </form>
      </div>
    </>
  );
};

export default AddQueuePage;
