import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const TermsPage = () => {
  const { t } = useTranslation();

  return (
    <div className="def-div">
      <h1 className="ta-c">{t("Terms")}</h1>
      <h4>Last updated May 01, 2023</h4>
      <h2>AGREEMENT TO OUR LEGAL TERMS</h2>
      <p>
        We are PSNC Future Labs ('Company', 'we', 'us', or 'our'), a company
        registered in Poland at Zwierzyniecka 20, Poznań 60-814 Poznań. Our VAT
        number is 7770002062.
        <br />
        <br /> We operate the website waitsafe.psnc.pl (the 'Site'), as well as
        any other related products and services that refer or link to these
        legal terms (the 'Legal Terms') (collectively, the 'Services').
        <br />
        <br /> You can contact us by phone at +48-61-858-5190, email at{" "}
        <a href="mailto:kontakt@futurelabs.psnc.pl.">
          kontakt@futurelabs.psnc.pl
        </a>
        , or by mail to Zwierzyniecka 20, 60-814 Poznań, Poland.
        <br />
        <br /> These Legal Terms constitute a legally binding agreement made
        between you, whether personally or on behalf of an entity ('you'), and
        PSNC Future Labs, concerning your access to and use of the Services. You
        agree that by accessing the Services, you have read, understood, and
        agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH
        ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING
        THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
        <br />
        <br /> Supplemental terms and conditions or documents that may be posted
        on the Services from time to time are hereby expressly incorporated
        herein by reference. We reserve the right, in our sole discretion, to
        make changes or modifications to these Legal Terms from time to time. We
        will alert you about any changes by updating the 'Last updated' date of
        these Legal Terms, and you waive any right to receive specific notice of
        each such change. It is your responsibility to periodically review these
        Legal Terms to stay informed of updates. You will be subject to, and
        will be deemed to have been made aware of and to have accepted, the
        changes in any revised Legal Terms by your continued use of the Services
        after the date such revised Legal Terms are posted.
        <br />
        <br /> All users who are minors in the jurisdiction in which they reside
        (generally under the age of 18) must have the permission of, and be
        directly supervised by, their parent or guardian to use the Services. If
        you are a minor, you must have your parent or guardian read and agree to
        these Legal Terms prior to you using the Services. We recommend that you
        print a copy of these Legal Terms for your records.
      </p>
      <h2>1. OUR SERVICES</h2>
      <p>
        The information provided when using the Services is not intended for
        distribution to or use by any person or entity in any jurisdiction or
        country where such distribution or use would be contrary to law or
        regulation or which would subject us to any registration requirement
        within such jurisdiction or country. Accordingly, those persons who
        choose to access the Services from other locations do so on their own
        initiative and are solely responsible for compliance with local laws, if
        and to the extent local laws are applicable.
      </p>
      <h2>2. INTELLECTUAL PROPERTY RIGHTS</h2>
      <h3>Our intellectual property</h3>
      <p>
        We are the owner or the licensee of all intellectual property rights in
        our Services, including all source code, databases, functionality,
        software, website designs, audio, video, text, photographs, and graphics
        in the Services (collectively, the 'Content'), as well as the
        trademarks, service marks, and logos contained therein (the 'Marks').
        <br />
        <br />
        Our Content and Marks are protected by copyright and trademark laws (and
        various other intellectual property rights and unfair competition laws)
        and treaties in the United States and around the world. <br />
        <br />
        The Content and Marks are provided in or through the Services 'AS IS'
        for your personal, non-commercial use or internal business purpose only.
      </p>
      <h3>Your use of our Services</h3>
      <p>
        Subject to your compliance with these Legal Terms, including the
        'PROHIBITED ACTIVITIES' section below, we grant you a non-exclusive,
        non-transferable, revocable licence to:{" "}
        <ul>
          <ul>
            <li>access the Services; and</li>
            <li>
              {" "}
              download or print a copy of any portion of the Content to which
              you have properly gained access.
            </li>
            <li>
              solely for your personal, non-commercial use or internal business
              purpose.
            </li>
          </ul>
        </ul>{" "}
        Except as set out in this section or elsewhere in our Legal Terms, no
        part of the Services and no Content or Marks may be copied, reproduced,
        aggregated, republished, uploaded, posted, publicly displayed, encoded,
        translated, transmitted, distributed, sold, licensed, or otherwise
        exploited for any commercial purpose whatsoever, without our express
        prior written permission.
        <br />
        <br /> If you wish to make any use of the Services, Content, or Marks
        other than as set out in this section or elsewhere in our Legal Terms,
        please address your request to: kontakt@futurelabs.psnc.pl. If we ever
        grant you the permission to post, reproduce, or publicly display any
        part of our Services or Content, you must identify us as the owners or
        licensors of the Services, Content, or Marks and ensure that any
        copyright or proprietary notice appears or is visible on posting,
        reproducing, or displaying our Content.
        <br />
        <br /> We reserve all rights not expressly granted to you in and to the
        Services, Content, and Marks. Any breach of these Intellectual Property
        Rights will constitute a material breach of our Legal Terms and your
        right to use our Services will terminate immediately.
      </p>
      <h2>3. PROHIBITED ACTIVITIES</h2>
      <p>
        You may not access or use the Services for any purpose other than that
        for which we make the Services available. The Services may not be used
        in connection with any commercial endeavours except those that are
        specifically endorsed or approved by us. As a user of the Services, you
        agree not to:
        <ul>
          <ul>
            <li>
              Systematically retrieve data or other content from the Services
              without written permission.
            </li>
            <li>Trick, defraud, or mislead us and other users.</li>
            <li>
              Circumvent, disable, or otherwise interfere with security-related
              features of the Services.
            </li>
            <li>
              Disparage, tarnish, or otherwise harm us and/or the Services.
            </li>
            <li>
              Use any information obtained from the Services to harass, abuse,
              or harm another person.
            </li>
            <li>
              Make improper use of our support services or submit false reports
              of abuse or misconduct.
            </li>
            <li>
              Use the Services in a manner inconsistent with any applicable laws
              or regulations.
            </li>
            <li>
              Engage in unauthorized framing of or linking to the Services.
            </li>
            <li>
              Upload or transmit viruses, Trojan horses, or other material that
              interferes with the Services.
            </li>
            <li>Engage in any automated use of the system.</li>
            <li>
              Delete the copyright or other proprietary rights notice from any
              Content.
            </li>
            <li>Attempt to impersonate another user or person.</li>
            <li>
              Upload or transmit any material that acts as a passive or active
              information collection or transmission mechanism.
            </li>
            <li>
              Interfere with, disrupt, or create an undue burden on the Services
              or the networks or services connected to the Services.
            </li>
            <li>
              Harass, annoy, intimidate, or threaten any of our employees or
              agents.
            </li>
            <li>
              Attempt to bypass any measures of the Services designed to prevent
              or restrict access.
            </li>
            <li>Copy or adapt the Services' software.</li>
            <li>
              Decipher, decompile, disassemble, or reverse engineer any of the
              software comprising the Services.
            </li>
            <li>
              Use, launch, develop, or distribute any automated system that
              accesses the Services.
            </li>
            <li>
              Use a buying agent or purchasing agent to make purchases on the
              Services.
            </li>
            <li>Make any unauthorized use of the Services.</li>
            <li>
              Use the Services as part of any effort to compete with us or
              otherwise use the Services and/or the Content for any
              revenue-generating endeavor or commercial enterprise.
            </li>
          </ul>
        </ul>
      </p>
      <h2>4. USER GENERATED CONTRIBUTIONS</h2>
      <p>The Services does not offer users to submit or post content.</p>
      <h2>5. PRIVACY POLICY</h2>
      <p>
        We care about data privacy and security. Please review our Privacy
        Policy: <Link to="/privacy">waitsafe.psnc.pl/privacy</Link>. By using
        the Services, you agree to be bound by our Privacy Policy, which is
        incorporated into these Legal Terms. Please be advised the Services are
        hosted in Poland.
        <br /> If you access the Services from any other region of the world
        with laws or other requirements governing personal data collection, use,
        or disclosure that differ from applicable laws in Poland, then through
        your continued use of the Services, you are transferring your data to
        Poland, and you expressly consent to have your data transferred to and
        processed in Poland.
      </p>
      <h2>6. Contact Information</h2>
      <p>
        For any questions or concerns regarding the privacy policy, please send{" "}
        <strong>us</strong> an email to{" "}
        <a href="mailto:kontakt@futurelabs.psnc.pl.">
          kontakt@futurelabs.psnc.pl
        </a>
        .<br />
        <br /> PSNC Future Labs
        <br /> Zwierzyniecka 20, 60-814 Poznań
        <br /> Poland
      </p>
    </div>
  );
};

export default TermsPage;
