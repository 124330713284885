import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useEditReaderMutation,
  useGetReaderQuery,
} from "../../../redux/serverApi";
import BackToAdmin from "../../prototypes/BackToAdmin";
import Button from "../../prototypes/Button";
import Loading from "../../prototypes/Loading";
import {
  successNotification,
  warnNotification,
  errorNotification,
} from "../../../utils/popNotifications";
import { useTranslation } from "react-i18next";

const EditReaderPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { rID } = useParams();
  const { data, isLoading } = useGetReaderQuery(rID);
  const [readerID, setReaderID] = useState("");
  const [queueID, setQueueID] = useState(0);
  const [editReader] = useEditReaderMutation();

  useEffect(() => {
    if (!isLoading && data) {
      setReaderID(data.readerID);
      setQueueID(data.queueID);
    }
  }, [data, isLoading]);

  const handleButton = (e) => {
    e.preventDefault();
    const payload = {
      readerID: readerID,
      queueID: queueID,
    };
    editReader({ id: rID, payload })
      .unwrap()
      .then((res) => {
        if (process.env.NODE_ENV === "development") {
          console.log(res);
        }
        successNotification(
          `${t("Success")}`,
          `${t("Reader") + " " + t("Updated")}`
        );
        navigate("/admin/all-readers");
      })
      .catch((err) => {
        console.error(err.message);
        if (err.data.message === "Not Exist") {
          warnNotification(`Warning`, err.data.error);
        } else {
          errorNotification(
            `Error`,
            `${t("Reader-editing-failed")}! ${t("Try-again")}.`
          );
        }
      });
  };

  return (
    <>
      <div className="def-div mb-3">
        <h1>{t("Edit") + " " + t("Reader")}</h1>
        <BackToAdmin />
        {isLoading && <Loading />}
        {!isLoading && (
          <form onSubmit={(e) => handleButton(e)}>
            <label>
              {t("Reader-id")}:
              <input
                type="text"
                name="readerID"
                id="readerID"
                value={readerID}
                onChange={(e) => {
                  setReaderID(e.target.value);
                }}
                onKeyDown={(e) => {
                  setReaderID(e.target.value);
                }}
              />
            </label>
            <label>
              {t("Function")} / {t("Queue") + " "} (id) <br />
              <small>{t("Readers-func")}</small>
              <input
                type="number"
                min={0}
                name="queue"
                id="queue"
                value={queueID}
                onChange={(e) => setQueueID(e.target.value)}
              />
            </label>
            <Button onClick={(e) => handleButton(e)}>{t("Update")}</Button>
          </form>
        )}
      </div>
    </>
  );
};

export default EditReaderPage;
