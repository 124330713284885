import { useEffect, useState } from "react";
import axios from "axios";
import { URL_BASE } from "../consts";

const useFetch = (url, interval = null) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: response } = await axios.get(URL_BASE + url);
        setData(response);
      } catch (error) {
        console.error(error.message);
      }
      setLoading(false);
    };
    fetchData();
    if (interval) {
      const int = setInterval(() => {
        fetchData();
      }, interval);
      return () => clearInterval(int);
    }
  }, [url, interval]);

  return {
    data,
    loading,
  };
};

export default useFetch;
