import React, { useState } from "react";
import Button from "../prototypes/Button";
import { useNavigate } from "react-router-dom";
import postData from "../../utils/postData";
import { setAuthToken } from "../../utils/setAuthToken";
import setUser from "../../utils/setUser";
import { useTranslation } from "react-i18next";

const LoginPage = () => {
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = () => {
    postData("login", { username: username, password: password }).then((r) => {
      try {
        const token = r.data.access_token;
        localStorage.setItem("token", token);
        setAuthToken(token);
        setUser();
        navigate("/");
      } catch (error) {
        setError(t("Bad-credentials"));
        console.error(error);
      }
    });
  };

  var errorProps = {};
  if (error) {
    errorProps.className = "error";
  }

  return (
    <div className="def-div">
      <h1 className="ta-c">{t("Login")}</h1>
      <form
        onSubmit={() => {
          handleLogin();
        }}
      >
        <label htmlFor="username">{t("Username")}</label>
        <br />
        <input
          type="text"
          name="username"
          id="username"
          autocomplete="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          onKeyDown={(e) => setUsername(e.target.value)}
          {...errorProps}
        ></input>
        <label htmlFor="password">{t("Password")}</label>
        <br />
        <input
          type="password"
          name="password"
          id="password"
          autocomplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={(e) => setPassword(e.target.value)}
          {...errorProps}
        ></input>
        {error && <div className="error-text">{t("Bad-credentials")}!</div>}{" "}
        {/* Display error message */}
      </form>
      <div className="flex-end">
        <Button
          onClick={() => {
            handleLogin();
          }}
        >
          {t("Login")}
        </Button>
      </div>
    </div>
  );
};

export default LoginPage;
