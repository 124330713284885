import React from "react";

const Button = (props) => {
  return (
    <button
      className={
        "btn " +
        (props.variant ? "btn-" + props.variant : "btn-primary") +
        " " +
        (props.className ? props.className : "")
      }
      {...props}
    >
      {props.children}
    </button>
  );
};

export default Button;
