import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { REFRESH_INTERVAL } from "../../utils/consts";
import { useDispatch, useSelector } from "react-redux";
import Queue from "../prototypes/Queue";
import TurnOnNotifications from "../prototypes/TurnOnNotifications";
import { fetchQueue, setFirstVisitChange } from "../../redux/queueSlice";
import { useTranslation } from "react-i18next";
import Instruction from "../layout/Instruction";

const QueuePage = () => {
  const { t } = useTranslation();

  const queueParams = new URLSearchParams(window.location.search);
  const viewType = Number(queueParams.get("view"));
  const viewedCardId = queueParams.get("cardid");

  const { queueID } = useParams();
  const data = useSelector((state) => state.queue.activeQueues[queueID]);
  const firstVisitChange = useSelector(
    (state) => state.queue.firstVisitChange[queueID]
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setFirstVisitChange({ queueID, property: false }));
  }, [dispatch, queueID]);

  useEffect(() => {
    dispatch(fetchQueue(queueID));
    const int = setInterval(() => {
      dispatch(fetchQueue(queueID));
    }, REFRESH_INTERVAL);
    return () => clearInterval(int);
  }, [dispatch, queueID]);
  if (firstVisitChange) {
    setTimeout(() => {
      dispatch(setFirstVisitChange({ queueID, property: false }));
    }, 10000);
  }
  return (
    <>
      <div className="def-div mb-3 queue-head-container">
        <h2>
          {t("Office")} {data && data.queueID}
        </h2>
        <h1>{data && data.name}</h1>
      </div>
      {data && data.visits && (
        <Queue
          data={data.visits.slice(0, data.visits.length)}
          loading={data ? false : true}
          waitTimeMin={data.waitTimeMin ? data.waitTimeMin : 0}
          firstVisitChange={firstVisitChange}
          viewType={viewType}
          vievedCardId={viewedCardId ? viewedCardId : null}
        />
      )}
      {/*<Instruction />*/}
      <TurnOnNotifications cardID={viewedCardId} />
    </>
  );
};

export default QueuePage;
