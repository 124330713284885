import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const BackToAdmin = (props) => {
  const { t } = useTranslation();
  return (
    <p>
      <Link className="button-link" to="/admin" >
      <FontAwesomeIcon icon={faHome} style={{paddingRight:"5px"}}/>{t("Back-to-admin-panel")}
      </Link>
    </p>
  );
};

export default BackToAdmin;
