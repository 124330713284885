import { configureStore } from "@reduxjs/toolkit";
import { queueReducer, listenerMiddleware } from "./queueSlice";
import { serverApi } from "./serverApi";

export default configureStore({
  reducer: {
    queue: queueReducer,
    [serverApi.reducerPath]: serverApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .prepend(listenerMiddleware.middleware)
      .concat(serverApi.middleware),
  devServer: {
    setupMiddlewares: (middlewares) => {
      return middlewares;
    },
  },
});
