import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteVisitQuery } from "../../../redux/serverApi";
import BackToAdmin from "../../prototypes/BackToAdmin";
import { successNotification } from "../../../utils/popNotifications";
import { useTranslation } from "react-i18next";

const DeleteVisitPage = () => {
  const { t } = useTranslation();
  const { vID } = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useDeleteVisitQuery(vID);

  const handleDelete = () => {
    if (process.env.NODE_ENV === "development") {
      console.log(data);
    }
    successNotification(
      `${t("Success")}`,
      `${t("Visit") + " " + t("Removed") + " - " + vID}`
    );
    navigate("/edit-queue/" + data.queueID);
  };

  return (
    <>
      <div className="def-div mb-3">
        <h1>
          {t("Visit") + " " + t("Removed") + " - "} {vID}
        </h1>
        <BackToAdmin />
        {!isLoading && data && handleDelete()}
      </div>
    </>
  );
};

export default DeleteVisitPage;
