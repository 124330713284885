import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  useEditQueueMutation,
  useGetQueueQuery,
} from "../../../redux/serverApi";
import BackToAdmin from "../../prototypes/BackToAdmin";
import Button from "../../prototypes/Button";
import Loading from "../../prototypes/Loading";
import {
  successNotification,
  errorNotification,
} from "../../../utils/popNotifications";

const EditQueuePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { queue } = useParams();
  const { data, isLoading } = useGetQueueQuery(queue);
  const [queueID, setQueueID] = useState(0);
  const [name, setName] = useState("");
  const [visibleInMenu, setVisibleInMenu] = useState(false);
  const [waitTimeMin, setWaitTimeMin] = useState(15);
  const [editQueue] = useEditQueueMutation();

  useEffect(() => {
    if (!isLoading && data) {
      const { queueID, name, visibleInMenu, waitTimeMin } = data;
      setQueueID(queueID);
      setName(name);
      setWaitTimeMin(waitTimeMin);
      setVisibleInMenu(visibleInMenu);
    }
  }, [queue, data, isLoading]);

  const handleButton = (e) => {
    e.preventDefault();

    const payload = {
      queueID,
      name,
      waitTimeMin,
      visibleInMenu,
    };

    editQueue({ id: queue, payload })
      .unwrap()
      .then((res) => {
        successNotification(`${t("Success")}`, `${t("Queue-edited")}`);
        navigate("/admin/all-queues");
      })
      .catch((err) => {
        console.error(err.message);
        errorNotification(
          `Error`,
          `${t("Queue-editing-failed")}! ${t("Try-again")}.`
        );
      });
  };

  return (
    <>
      <div className="def-div mb-3">
        <h1>{t("Edit-queue")}</h1>
        <BackToAdmin />
        {isLoading && <Loading />}
        {!isLoading && (
          <form onSubmit={(e) => handleButton(e)}>
            <label>
              {t("Queue") + " "}ID
              <input
                type="number"
                min={0}
                name="queue"
                id="queue"
                value={queueID}
                onChange={(e) => setQueueID(e.target.value)}
              />
            </label>
            <label>
              {t("Queue-name")}:
              <input
                type="text"
                name="name"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </label>
            <label>
              {t("Visible-in-menu")}
              <input
                type="checkbox"
                name="visibleInMenu"
                id="visibleInMenu"
                checked={visibleInMenu}
                onChange={(e) => setVisibleInMenu(!visibleInMenu)}
              />
            </label>
            <label>
              {t("queue_wait_time")}:
              <input
                type="number"
                min={1}
                name="queue-wait-time"
                id="queue-wait-time"
                value={waitTimeMin}
                onChange={(e) => setWaitTimeMin(Number(e.target.value))}
              />
            </label>
            <Button onClick={(e) => handleButton(e)}>{t("Update")}</Button>
          </form>
        )}
      </div>
    </>
  );
};

export default EditQueuePage;
