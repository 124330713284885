import React, { useState } from "react";
import { useAddVisitMutation } from "../../../redux/serverApi";
import BackToAdmin from "../../prototypes/BackToAdmin";
import Button from "../../prototypes/Button";
import {
  successNotification,
  warnNotification,
  errorNotification,
} from "../../../utils/popNotifications";
import { useTranslation } from "react-i18next";

const AddVisitPage = () => {
  const { t } = useTranslation();
  const [cardNumber, setCardNumber] = useState("");
  const [queueID, setQueueID] = useState(0);
  const [addVisit] = useAddVisitMutation();

  const handleButton = (e) => {
    e.preventDefault();
    const card = {
      cardNumber: cardNumber,
      queueID: queueID,
    };
    addVisit({ payload: card })
      .unwrap()
      .then((res) => {
        if (process.env.NODE_ENV === "development") {
          console.log(res);
        }
        successNotification(`${t("Success")}`, `${t("Visit-added")}`);
      })
      .catch((err) => {
        console.error(err.message);
        if (err.data.message === "Exists") {
          warnNotification(`Warning`, err.data.error);
        } else {
          errorNotification(`Error`, `${t("Error-while-adding")}!`);
        }
      });
  };

  return (
    <>
      <div className="def-div mb-3">
        <h1>{t("Add-a-visit")}</h1>
        <BackToAdmin />
        <form onSubmit={(e) => handleButton(e)}>
          <label>
            {t("Card-number")}:
            <input
              type="text"
              name="cardNumber"
              id="cardNumber"
              value={cardNumber}
              onChange={(e) => {
                setCardNumber(e.target.value);
              }}
              onKeyDown={(e) => {
                setCardNumber(e.target.value);
              }}
            />
          </label>
          <label>
            {t("Queue")}: (id)
            <input
              type="number"
              min={0}
              name="queue"
              id="queue"
              value={queueID}
              onChange={(e) => setQueueID(e.target.value)}
            />
          </label>
          <Button onClick={(e) => handleButton(e)}>{t("Add")}</Button>
        </form>
      </div>
    </>
  );
};

export default AddVisitPage;
