import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AdminPanelPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="def-div mb-3">
        <h1>{t("Administration-panel")}</h1>
      </div>
      <div className="admin-panel-container">
        <div className="admin-panel-card">
          <h2>{t("Visits")}</h2>
          <Link className="btn btn-primary" to="/admin/add-visit">
            {t("Add-a-visit")}
          </Link>
        </div>
        <div className="admin-panel-card">
          <h2>{t("Cards")}</h2>
          <Link className="btn btn-primary" to="/admin/all-cards">
            {t("All-cards")}
          </Link>
          <Link className="btn btn-primary" to="/admin/add-card">
            {t("Add-card")}
          </Link>
        </div>

        <div className="admin-panel-card">
          <h2>{t("Queues")}</h2>
          <Link className="btn btn-primary" to="/admin/all-queues">
            {t("All-queues")}
          </Link>
          <Link className="btn btn-primary" to="/admin/add-queue">
            {t("Add-queue")}
          </Link>
        </div>

        <div className="admin-panel-card">
          <h2>{t("Readers")}</h2>
          <Link className="btn btn-primary" to="/admin/all-readers">
            {t("All-readers")}
          </Link>
          <Link className="btn btn-primary" to="/admin/add-reader">
            {t("Add-reader")}
          </Link>
        </div>

        <div className="admin-panel-card">
          <h2>{t("Statistics")}</h2>
          <Link className="btn btn-primary" to="/admin/statistics">
            {t("View-data")}
          </Link>
        </div>
      </div>
    </>
  );
};

export default AdminPanelPage;
