import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Card = (props) => {
  const { t } = useTranslation();
  return (
    <div className="card-item">
      <img
        src={`/images/icons/new-design/${props.icon}.png`}
        alt={props.icon}
        className="queue-item-icon"
      ></img>
      <h1>{props.cardNumber}</h1>
      <h2>{props.name}</h2>
      <h2>
        {t("Queue")}: {props.queueID}
      </h2>

      <div>
        <Link
          className="btn-primary btn-admin btn-admin-edit"
          to={"/admin/edit-card/" + props.cardNumber}
        >
          {t("Edit")}
        </Link>
        <Link
          className="btn-primary btn-admin btn-admin-remove"
          to={"/admin/delete-card/" + props.cardNumber}
        >
          {t("Remove")}
        </Link>
      </div>
    </div>
  );
};

export default Card;
