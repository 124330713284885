import React, { useState, useEffect, useCallback, useMemo } from "react";
import Button from "../prototypes/Button";
import { useNavigate } from "react-router-dom";
import postData from "../../utils/postData";
import { parseCheckOutTime } from "../../utils/functions";
import {
  validatePhoneNumber,
  validateCardId,
} from "../../utils/dataValidators";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import {
  successNotification,
  errorNotification,
} from "../../utils/popNotifications";
import { useTranslation } from "react-i18next";

const NotificationsPage = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation("home");
  const navigate = useNavigate();

  const [cardId, setCardId] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [errorPhone, setErrorPhone] = useState(false);
  const [errorCard, setErrorCard] = useState(false);

  const queueParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );
  const cardIdViaLink = useMemo(
    () => String(queueParams.get("cardid")),
    [queueParams]
  );

  const checkPhoneNumber = useCallback((phoneNumber) => {
    if (validatePhoneNumber(phoneNumber)) {
      setErrorPhone(false);
      return true;
    }
    setErrorPhone(true);
    return false;
  }, []);

  const checkCardId = useCallback((cardId) => {
    if (validateCardId(cardId)) {
      setErrorCard(false);
      return true;
    } else {
      setErrorCard(true);
      return false;
    }
  }, []);

  const handlePhoneChange = useCallback(
    (value) => {
      setPhoneNumber(value);
      checkPhoneNumber(value);
    },
    [checkPhoneNumber]
  );

  const handleCardChange = useCallback(
    (value) => {
      if (value.length <= 4) {
        const numbersOnly = value.replace(/\D/g, "");
        checkCardId(numbersOnly);
        setCardId(numbersOnly);
      }
    },
    [checkCardId]
  );

  const turnOnSMS = useCallback(
    (phoneNumber) => {
      if (phoneNumber) {
        const payload = {
          time: parseCheckOutTime(Date.now(), 3),
          cardNumber: cardId,
          phoneNumber: phoneNumber,
        };
        postData(`sms/new`, payload);
        successNotification(
          `${t("Success")}`,
          `${t("Sms-notifications-enabled")}! ${t("Check-your-phone")}.`
        );
      }
    },
    [cardId, t]
  );

  const handleClick = (e) => {
    e.preventDefault();
    if (checkPhoneNumber(PhoneNumber) && checkCardId(cardId)) {
      turnOnSMS(PhoneNumber);
      navigate(`/card/${cardId}`);
    } else {
      errorNotification(`Error`, `${t("Error-while-adding")}!`);
    }
  };

  useEffect(() => {
    if (cardIdViaLink && checkCardId(cardIdViaLink)) {
      setCardId(cardIdViaLink);
    }
  }, [cardIdViaLink, checkCardId]);

  var errorCardProps = {};
  var errorPhoneProps = {};
  if (errorCard) {
    errorCardProps.className = "error";
  }
  if (errorPhone) {
    errorPhoneProps.className = "error";
  }

  return (
    <div className="def-div home-container">
      <h1 className="ta-c">{t("Notifications")}</h1>
      <p>{t("Register-phone-number-for-SMS-notifications")}</p>
      <form
        onSubmit={(e) => {
          handleClick(e);
        }}
      >
        <label htmlFor="card-id">{t("Wait-safe-card-umber")}*</label>
        <br />
        <input
          type="text"
          required
          name="card-id"
          id="card-id"
          value={cardId}
          onChange={(e) => {
            handleCardChange(e.target.value);
          }}
          onKeyDown={(e) => {
            handleCardChange(e.target.value);
          }}
          placeholder="0000"
          {...errorCardProps}
        ></input>

        <label htmlFor="phone-number">{t("Enter-phone-number")}</label>
        <br />
        <PhoneInput
          s={flags}
          {...errorPhoneProps}
          id={"country-select"}
          placeholder="+00 000 000 000"
          value={PhoneNumber}
          defaultCountry={i18n.language.toUpperCase()}
          international={true}
          onChange={handlePhoneChange}
          countries={[
            "PL",
            "GB",
            "BE",
            "LT",
            "PT",
            "BG",
            "ES",
            "LU",
            "RO",
            "CZ",
            "FR",
            "HU",
            "SI",
            "DK",
            "HR",
            "MT",
            "DE",
            "IT",
            "NL",
            "FI",
            "EE",
            "CY",
            "AT",
            "SE",
            "IE",
            "LV",
            "IS",
            "NO",
            "LI",
            "CH",
          ]}
          addInternationalOption={false}
          countryOptionsOrder={["PL", "GB", "ES", "..."]}
          required
        />
        <p>*{t("Mandatory-field")}</p>
      </form>
      <div className="flex-end">
        <Button
          onClick={(e) => {
            handleClick(e);
          }}
        >
          {t("Enable-SMS-notifications")}
        </Button>
      </div>
    </div>
  );
};

export default NotificationsPage;
