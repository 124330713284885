import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import { useTranslation } from "react-i18next";

const WeekAverageTimeChart = ({ data }) => {
  const { t } = useTranslation();
  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  const updateChart = (data) => {
    if (chartRef.current && data) {
      if (chartInstance) {
        chartInstance.destroy();
      }

      const queueNames = Object.keys(data);

      const datasets = queueNames.map((queueName) => {
        const averageTimes = Object.values(data[queueName]).map(
          (dayData) => dayData.averageTime
        );

        return {
          label: queueName,
          data: averageTimes,
          fill: false,
        };
      });
      const chartData = {
        labels: [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ],
        datasets: datasets,
      };

      const chartOptions = {
        scales: {
          y: {
            beginAtZero: true,
            display: true,
            title: {
              display: true,
              text: t("minutes"),
            },
          },
        },
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: true,
          },
        },
      };

      const ctx = chartRef.current.getContext("2d");
      const newChartInstance = new Chart(ctx, {
        type: "line",
        data: chartData,
        options: chartOptions,
      });

      setChartInstance(newChartInstance);
    }
  };

  useEffect(() => {
    if (data) {
      updateChart(data);
    }
  }, [data, t]);

  return (
    <div className="chrtPlots">
      <p>
        {t("Average-time")} - {t("week-view")}
      </p>
      <div>
        <canvas ref={chartRef} width={400} height={300}></canvas>
      </div>
    </div>
  );
};

export default WeekAverageTimeChart;
