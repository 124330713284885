import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { REFRESH_INTERVAL } from "../../../utils/consts";
import useFetch from "../../../utils/hooks/useFetch";
import DnDQueue from "../../prototypes/DnDQueue";
import BackToAdmin from "../../prototypes/BackToAdmin";
import { useTranslation } from "react-i18next";

const EditOrderPage = () => {
  const refreshInt = 10000;
  const { t } = useTranslation();
  const { queueID } = useParams();
  const [timeToNextRefresh, setTimeToNextRefresh] = useState(
    Math.floor(refreshInt / 1000) - 1
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeToNextRefresh(timeToNextRefresh - 1);
      if (timeToNextRefresh === 0) {
        setTimeToNextRefresh(Math.floor(refreshInt / 1000) - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timeToNextRefresh]);

  const { data, loading } = useFetch(`queue/${queueID}`, refreshInt);

  return (
    <>
      <div className="def-div mb-3" style={{ textAlign: "center" }}>
        <h1>{t("Edit-queue")}</h1>
        {data ? (
          <>
            <h2>
              {t("Office")} {data.queueID}
            </h2>
            <h1>{data.name}</h1>
          </>
        ) : (
          ""
        )}
        <BackToAdmin />
      </div>
      <p>
        {t("Time-to-next-refresh")}: {timeToNextRefresh} s
      </p>
      {data && data.visits && (
        <DnDQueue data={data.visits} loading={loading} queueID={queueID} />
      )}
    </>
  );
};

export default EditOrderPage;
