import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteQueueQuery } from "../../../redux/serverApi";
import BackToAdmin from "../../prototypes/BackToAdmin";
import { successNotification } from "../../../utils/popNotifications";
import { useTranslation } from "react-i18next";

const DeleteQueuePage = () => {
  const { t } = useTranslation();
  const { queueId } = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useDeleteQueueQuery(queueId);

  const handleDelete = () => {
    if (process.env.NODE_ENV === "development") {
      console.log(data);
    }
    successNotification(
      `${t("Success")}`,
      `${t("Queue") + " " + t("Removed") + " - " + queueId}`
    );
    navigate("/admin/all-queues");
  };

  return (
    <>
      <div className="def-div mb-3">
        <h1>
          {t("Queue") + " " + t("Removed") + " - "} {queueId}
        </h1>
        <BackToAdmin />
        {!isLoading && data && handleDelete()}
      </div>
    </>
  );
};

export default DeleteQueuePage;
