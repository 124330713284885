import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AddCardPage from "./pages/admin/AddCardPage";
import AddQueuePage from "./pages/admin/AddQueuePage";
import AddReaderPage from "./pages/admin/AddReaderPage";
import AddVisitPage from "./pages/admin/AddVisitPage";
import AdminPanelPage from "./pages/admin/AdminPanelPage";
import AllCardsPage from "./pages/admin/AllCardsPage";
import AllQueuePage from "./pages/admin/AllQueuePage";
import AllReadersPage from "./pages/admin/AllReadersPage";
import DeleteCardPage from "./pages/admin/DeleteCardPage";
import DeleteQueuePage from "./pages/admin/DeleteQueuePage";
import DeleteReaderPage from "./pages/admin/DeleteReaderPage";
import DeleteVisitPage from "./pages/admin/DeleteVisitPage";
import EditCardPage from "./pages/admin/EditCardPage";
import EditQueuePage from "./pages/admin/EditQueuePage";
import EditReaderPage from "./pages/admin/EditReaderPage";
import EndVisitPage from "./pages/admin/EndVisitPage";
import EditOrderPage from "./pages/admin/EditOrderPage";
import StatisticsPage from "./pages/admin/StatisticsPage";
import RedirectByCardNumber from "./pages/RedirectByCardNumber";
import ClinicPage from "./pages/ClinicPage";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import HelpPage from "./pages/HelpPage";
import PrivacyPage from "./pages/PrivacyPage";
import TermsPage from "./pages/TermsPage";
import NotificationsPage from "./pages/NotificationsPage";
import QueuePage from "./pages/QueuePage";
import ProtectedRoute from "./prototypes/ProtectedRoute";
import NotFoundPage from "./pages/NotFoundPage";

const ContentSelector = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/notifications" element={<NotificationsPage />} />
    <Route path="/login" element={<LoginPage />} />
    <Route path="/help" element={<HelpPage />} />
    <Route path="/privacy" element={<PrivacyPage />} />
    <Route path="/terms" element={<TermsPage />} />
    <Route path="/queue/:queueID" element={<QueuePage />} />
    <Route path="/card/:cardId" element={<RedirectByCardNumber />} />
    <Route
      exact
      path="/edit-queue/:queueID"
      element={
        <ProtectedRoute>
          <EditOrderPage />
        </ProtectedRoute>
      }
    />
    <Route path="/clinic/:id" element={<ClinicPage />} />
    <Route
      path="admin/"
      element={
        <ProtectedRoute>
          <AdminPanelPage />
        </ProtectedRoute>
      }
    ></Route>
    <Route
      path="admin/edit-card/:cardId"
      element={
        <ProtectedRoute>
          <EditCardPage />
        </ProtectedRoute>
      }
    ></Route>
    <Route
      path="admin/delete-card/:cardId"
      element={
        <ProtectedRoute>
          <DeleteCardPage />
        </ProtectedRoute>
      }
    ></Route>
    <Route
      path="admin/all-cards"
      element={
        <ProtectedRoute>
          <AllCardsPage />
        </ProtectedRoute>
      }
    ></Route>
    <Route
      path="admin/add-card"
      element={
        <ProtectedRoute>
          <AddCardPage />
        </ProtectedRoute>
      }
    ></Route>
    <Route
      path="admin/edit-queue/:queue"
      element={
        <ProtectedRoute>
          <EditQueuePage />
        </ProtectedRoute>
      }
    ></Route>
    <Route
      path="admin/delete-queue/:queueId"
      element={
        <ProtectedRoute>
          <DeleteQueuePage />
        </ProtectedRoute>
      }
    ></Route>
    <Route
      path="admin/all-queues"
      element={
        <ProtectedRoute>
          <AllQueuePage />
        </ProtectedRoute>
      }
    ></Route>
    <Route
      path="admin/add-queue"
      element={
        <ProtectedRoute>
          <AddQueuePage />
        </ProtectedRoute>
      }
    ></Route>
    <Route
      path="admin/edit-reader/:rID"
      element={
        <ProtectedRoute>
          <EditReaderPage />
        </ProtectedRoute>
      }
    ></Route>
    <Route
      path="admin/delete-reader/:rID"
      element={
        <ProtectedRoute>
          <DeleteReaderPage />
        </ProtectedRoute>
      }
    ></Route>
    <Route
      path="admin/all-readers"
      element={
        <ProtectedRoute>
          <AllReadersPage />
        </ProtectedRoute>
      }
    ></Route>
    <Route
      path="admin/add-reader"
      element={
        <ProtectedRoute>
          <AddReaderPage />
        </ProtectedRoute>
      }
    ></Route>
    <Route
      path="admin/delete-visit/:vID"
      element={
        <ProtectedRoute>
          <DeleteVisitPage />
        </ProtectedRoute>
      }
    ></Route>
    <Route
      path="admin/end-visit/:vID"
      element={
        <ProtectedRoute>
          <EndVisitPage />
        </ProtectedRoute>
      }
    ></Route>
    <Route
      path="admin/add-visit"
      element={
        <ProtectedRoute>
          <AddVisitPage />
        </ProtectedRoute>
      }
    ></Route>
    <Route
      path="admin/statistics"
      element={
        <ProtectedRoute>
          <StatisticsPage />
        </ProtectedRoute>
      }
    ></Route>
    <Route path="/404" element={<NotFoundPage></NotFoundPage>}></Route>
    <Route path="*" element={<Navigate to="/404"></Navigate>}></Route>
  </Routes>
);

export default ContentSelector;
