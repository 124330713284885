import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useGetAllQueuesQuery } from "../../../redux/serverApi";
import { ADMIN_POLLING_INTERVAL } from "../../../utils/consts";
import BackToAdmin from "../../prototypes/BackToAdmin";
import Loading from "../../prototypes/Loading";
import { useTranslation } from "react-i18next";

const QueueCard = (props) => {
  const { t } = useTranslation();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const handleRemoveClick = () => {
    setShowConfirmation(true);
  };

  const handleConfirmRemove = () => {
    window.location.href = "/admin/delete-queue/" + props.queueID;
  };

  const handleCancelRemove = () => {
    setShowConfirmation(false);
  };

  return (
    <div className="card-item">
      <em>
        {t("Hospital")}: {props.hospitalID}
      </em>
      <h2>{props.name}</h2>
      <h3>
        {t("Queue-id")}: {props.queueID}
      </h3>
      <div>
        <Link
          className="btn-primary btn-admin btn-admin-edit"
          to={"/admin/edit-queue/" + props.queueID}
        >
          {t("Edit")}
        </Link>
        <Link
          className="btn-primary btn-admin btn-admin-remove"
          //to={"/admin/delete-queue/" + props.queueID}
          onClick={handleRemoveClick}
        >
          {t("Remove")}
        </Link>

        <Link
          className="btn-primary btn-admin btn-admin-edit"
          to={"/edit-queue/" + props.queueID}
        >
          {t("Edit-visits-order")}
        </Link>
        {showConfirmation && (
          <div className="confirmation-modal">
            <p>
              {t("Are-you-shure-to-remove-this")} {t("Queue")}?
            </p>
            <button
              className={"btn-primary btn-admin"}
              onClick={handleConfirmRemove}
            >
              {t("Yes")}
            </button>
            <button
              className={"btn-primary btn-admin"}
              onClick={handleCancelRemove}
            >
              {t("No")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const AllQueuePage = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetAllQueuesQuery(0, ADMIN_POLLING_INTERVAL);
  return (
    <>
      <div className="def-div mb-3">
        <h1>{t("All-cards")}</h1>
        <BackToAdmin />
      </div>
      <div className="all-cards-container">
        {isLoading && <Loading />}
        {!data && !isLoading && (
          <div>
            <p>{t("Nothing-to-show")}</p>
          </div>
        )}
        {data && data.map((card, i) => <QueueCard key={i} {...card} />)}
      </div>
    </>
  );
};

export default AllQueuePage;
