import React, { useCallback, useState } from "react";
import Button from "../prototypes/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { validateCardId } from "../../utils/dataValidators";
import { warnNotification } from "../../utils/popNotifications";

const HomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [cardId, setCardId] = useState("");
  const [errorCard, setErrorCard] = useState(false);

  const checkCardId = useCallback((cardId) => {
    if (validateCardId(cardId)) {
      setErrorCard(false);
      return true;
    } else {
      setErrorCard(true);
      return false;
    }
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    if (checkCardId(cardId)) {
      navigate(`card/${cardId}`);
    } else {
      warnNotification(`${t("Invalid-card-number")}!`, `${t("Try-again")}!`);
    }
  };

  const handleCardChange = useCallback(
    (value) => {
      if (value.length <= 4) {
        const numbersOnly = value.replace(/\D/g, "");
        checkCardId(numbersOnly);
        setCardId(numbersOnly);
      }
    },
    [checkCardId]
  );
  var errorCardProps = {};
  if (errorCard) {
    errorCardProps.className = "error";
  }

  return (
    <div className="def-div home-container">
      <h1 className="ta-c">{t("Welcome")}</h1>
      <p>{t("Enter-card-details")}</p>
      <form
        onSubmit={(e) => {
          handleClick(e);
        }}
      >
        <label htmlFor="card-id">{t("Card-number").toUpperCase()}</label>
        <br />
        <input
          type="text"
          required
          name="card-id"
          id="card-id"
          value={cardId}
          onChange={(e) => {
            handleCardChange(e.target.value);
          }}
          onKeyDown={(e) => {
            handleCardChange(e.target.value);
          }}
          placeholder="0000"
          {...errorCardProps}
        ></input>
      </form>
      <Button
        className="btn btn-primary w100"
        onClick={(e) => {
          handleClick(e);
        }}
      >
        {t("Next")}
      </Button>
    </div>
  );
};

export default HomePage;
